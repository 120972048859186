import React, { useEffect } from 'react';
import Video from './Video';
import Layout from './layout';

const videoTemplate = ({ pageContext }) => {
	const { video, background, topic, slug, image } = pageContext;


	useEffect(() => {
		if(window.screen.orientation){
			try {
				window.screen.orientation.lock(`portrait-primary`);
			} catch(e){
				console.log(`can't lock screen: ${e}`)
			}
		} else if(window.screen.lockOrientation){
			try {
				window.screen.lockOrientation(`portrait-primary`);
			} catch(e){
				console.log(`can't lock screen: ${e}`)
			}
		}
	}, []);

	return (
		<Layout slug={slug} image={image} titleAddition={topic.title}>
			<div className="video-wrapper">
				<Video videoId={video} background={background} topic={topic} />
			</div>
		</Layout>
	);
};

export default videoTemplate;
